// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---components-theme-ui-atoms-mdx": () => import("/opt/build/repo/components/Theme/ui-atoms.mdx" /* webpackChunkName: "component---components-theme-ui-atoms-mdx" */),
  "component---components-theme-ui-molecules-mdx": () => import("/opt/build/repo/components/Theme/ui-molecules.mdx" /* webpackChunkName: "component---components-theme-ui-molecules-mdx" */),
  "component---components-theme-ui-rows-mdx": () => import("/opt/build/repo/components/Theme/ui-rows.mdx" /* webpackChunkName: "component---components-theme-ui-rows-mdx" */),
  "component---components-theme-ui-sandbox-mdx": () => import("/opt/build/repo/components/Theme/ui-sandbox.mdx" /* webpackChunkName: "component---components-theme-ui-sandbox-mdx" */),
  "component---components-theme-ui-branding-mdx": () => import("/opt/build/repo/components/Theme/ui-branding.mdx" /* webpackChunkName: "component---components-theme-ui-branding-mdx" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/.docz/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

